import React, { RiAttachmentLine } from 'react-icons/ri';

export function NoAttachmentFound() {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="p-4 bg-slate-100 rounded-full">
        <RiAttachmentLine className="w-6 h-6 text-slate-400" />
      </div>
      <small className="mt-4 text-sm text-slate-400">No attachment found</small>
    </div>
  );
}
