import { useCallback, useEffect, useRef } from 'react';
import { req, useApiGet, useApiLoading } from 'react-reqq-lite';

export const useDatasetOptions = (code, allowRefetch = false) => {
  const isLoading = useApiLoading(`DATASET/options/${code}`, 'get');
  const options = useApiGet(`DATASET/options/${code}`, []);
  const myRef = useRef();

  const fetch = useCallback(() => {
    if (!code) return;
    if (myRef.current && !allowRefetch) return;
    req.get({
      key: `DATASET/options/${code}`,
      url: '/dataset/v1/dataset',
      params: { code },
      transform: (res) =>
        (res?.data || []).map((x) => ({
          label: x.description,
          value: x.description,
        })),
      onSuccess: () => {
        myRef.current = true;
      },
      onError: () => {
        req.set(`DATASET/options/${code}`, [
          { label: 'Unable to load, Try again.', value: '__RETRY__' },
        ]);
      },
    });
  }, [code, allowRefetch]);
  useEffect(() => {
    fetch();
  }, [fetch]);
  return [isLoading, options, fetch];
};
