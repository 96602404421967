import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

function FormInputPin({ name, value, count, onChange, onSetFieldValue }) {
  const inputRef = useRef();

  const keepFocus = useCallback(() => inputRef.current?.focus(), []);

  const handleChange = ({ target }) => {
    if (typeof onChange === 'function') {
      onChange((state) => ({
        ...state,
        [name]: target.value,
      }));
    }
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, target.value);
    }
  };

  useEffect(keepFocus, [keepFocus]);

  return (
    <div className="relative">
      <button
        aria-label="focus"
        type="button"
        className="absolute inset-0 w-full h-full"
        onClick={keepFocus}
      />
      <input
        type="number"
        className="absolute opacity-0 w-full h-full inset-0"
        ref={inputRef}
        value={value || ''}
        inputMode="numeric"
        pattern="[0-9]*"
        autoComplete="one-time-code"
        onBlur={keepFocus}
        onChange={handleChange}
      />
      <div className="flex items-center space-x-4 w-full">
        {Array.from({ length: count }, (_, key) => key).map((item, i) => (
          <div
            key={`${item}`}
            className={`flex items-center justify-center w-10 h-12 text-lg border ${
              value.length === item && 'border-gray-700'
            }`}
          >
            {value[i] || ''}
          </div>
        ))}
      </div>
    </div>
  );
}

FormInputPin.defaultProps = {
  count: 6,
  onChange: false,
  onSetFieldValue: false,
};

FormInputPin.propTypes = {
  count: PropTypes.number,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onSetFieldValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

export default FormInputPin;
