import React from 'react';
import cn from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { toastSuccess } from 'react-geek-toast';
import { HiDuplicate } from 'react-icons/hi';

const InfoContext = React.createContext();

export const InfoDivider = ({ label }) => (
  <div className="p-4 font-semibold">{label}</div>
);

InfoDivider.propTypes = {
  label: PropTypes.string.isRequired,
};

export const InfoItemCopy = ({ value, label, className }) => {
  const { data } = React.useContext(InfoContext);
  const handleCopy = (e) => {
    e.preventDefault();
    toastSuccess('Copied to clipboard!');
  };
  return (
    <div className="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt className="text-sm font-medium text-gray-500">{label}</dt>
      <dd
        className={cn(
          'mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2',
          className
        )}
      >
        <a className="hover:underline" href="/" onClick={handleCopy}>
          <HiDuplicate className="h-5 w-5 inline-block mr-1" />
          {typeof value === 'function' ? value(data) : get(data, value)}
        </a>
      </dd>
    </div>
  );
};

InfoItemCopy.defaultProps = {
  className: '',
};

InfoItemCopy.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export const InfoItem = ({ value, label, className }) => {
  const { data } = React.useContext(InfoContext);
  return (
    <div className="py-3 grid grid-cols-3 gap-4 px-6">
      <dt className="text-sm font-medium text-gray-500">{label}</dt>
      <dd
        className={cn(
          'mt-1 text-sm text-gray-900 sm:mt-0 col-span-2 text-right',
          className
        )}
      >
        {typeof value === 'function' ? value(data) : get(data, value)}
      </dd>
    </div>
  );
};

InfoItem.defaultProps = {
  className: '',
};

InfoItem.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export const InfoItemSm = ({ value, label, className }) => {
  const { data } = React.useContext(InfoContext);
  return (
    <div className="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt className="text-sm font-medium text-gray-500">{label}</dt>
      <dd
        className={cn(
          'mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2',
          className
        )}
      >
        {typeof value === 'function' ? value(data) : get(data, value)}
      </dd>
    </div>
  );
};

InfoItemSm.defaultProps = {
  className: '',
};

InfoItemSm.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

function GenericInfo({ data, children }) {
  return (
    <InfoContext.Provider value={{ data }}>
      <div className="py-5 p-0">
        <dl className="sm:divide-y sm:divide-gray-200">{children}</dl>
      </div>
    </InfoContext.Provider>
  );
}

GenericInfo.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.instanceOf(Object).isRequired,
};

export default GenericInfo;
