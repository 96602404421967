import React from 'react';
import { MdReviews } from 'react-icons/md';

function InReviewCard() {
  return (
    <div className="bg-yellow-50 text-yellow-500 shadow-lg shadow-yellow-500/10 p-5 rounded-lg">
      <div className="flex space-x-2">
        <MdReviews className="w-8 h-8 flex-shrink-0" />
        <div className="space-y-1">
          <div className="text-slate-900 font-bold text-lg">
            Your account is{' '}
            <span className="text-yellow-500 font-bold">
              waiting for approval
            </span>
          </div>
          <div className="text-sm text-slate-700">
            Our onboarding team typically evaluates this up to 7 business days.
            Once your account has been accepted or if they have any more
            concerns, such as regarding missing documents or clarifications
            relating to your business, they will send you an email.
          </div>
        </div>
      </div>
    </div>
  );
}

export default InReviewCard;
