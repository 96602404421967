import { configureApi } from 'react-reqq-lite';
import AuthStore from 'auth-store';
import { get } from 'lodash';
import { toastError } from 'react-geek-toast';
import history from './history';

const store = configureApi({
  endpoint: process.env.REACT_APP_END_POINT,
  requestHeaders: () => {
    const token = AuthStore.get('_token');
    if (!token)
      return {
        'Content-Type': 'application/json',
      };
    return {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
  },
  timeout: 180000,
  onError: (err) => {
    const err_message = get(err, 'response.message');
    if (err.status === 401) {
      toastError(err?.response?.error_description || 'Unauthorized!');
      if (
        err?.response?.error === 'token_expired' ||
        err?.response?.error === 'unauthorized'
      )
        history.push('/logout');

      return;
    }
    // if (err.status === 422) {
    //   const message = get(
    //     err,
    //     `response.errors.${Object.keys(get(err, 'response.errors') || [])[0]}.0`
    //   );
    //   toastError(message || 'Unprocessable Entity!');
    //   return;
    // }
    if (err.status === 400) {
      toastError(err_message || 'Bad Request!');
      return;
    }
    // if (err.status === 500) {
    //   toastError(err_message || 'Internal Server Error!');
    //   return;
    // }
    if (err.status === 0) {
      toastError('Unable to connect to web service');
      return;
    }
    console.warn('unhandled error', err); // eslint-disable-line
  },
});

export default store;
