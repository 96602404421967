import React from 'react';
import PropTypes from 'prop-types';
import { MdReportGmailerrorred } from 'react-icons/md';

import { useOpenRejectionRemarksModal } from 'core-modules/dashboard/modals/RejectionRemarksModal';
import Button from 'partial/components/Button';

function RejectedCard({ merchantData }) {
  const openRejectionRemarlsModal = useOpenRejectionRemarksModal();
  return (
    <div className="bg-red-50 text-red-500 p-5 rounded-lg">
      <div className="flex space-x-2">
        <MdReportGmailerrorred className="w-8 h-8 flex-shrink-0" />
        <div className="space-y-1">
          <div className="text-slate-900 font-bold text-lg">
            Your account application is{' '}
            <span className="text-red-500 font-bold">rejected</span>
          </div>
          <div className="text-sm text-slate-700 space-y-2">
            <p>
              We&apos;re sorry to inform you that your application was rejected.
              Change the details that needs to be corrected and re-submit your
              application.
            </p>
            <Button
              danger
              onClick={() =>
                openRejectionRemarlsModal(merchantData?.approval_remarks || '')
              }
            >
              View Reason
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

RejectedCard.propTypes = {
  merchantData: PropTypes.instanceOf(Object).isRequired,
};

export default RejectedCard;
