import React from 'react';
import PropTypes from 'prop-types';
import FormSelect from 'partial/form/FormSelect';
import { useDatasetOptions } from '../hooks';

function FormDatasetOptions({ code, ...props }) {
  const [isLoading, options, retry] = useDatasetOptions(code);
  return (
    <FormSelect
      {...props}
      isLoading={isLoading}
      options={options}
      onRetry={retry}
    />
  );
}

FormDatasetOptions.propTypes = {
  code: PropTypes.string.isRequired,
};

export default FormDatasetOptions;
