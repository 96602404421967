import React, { useMemo } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

function ToolTip({ children, tip, className, direction }) {
  const directionClassname = useMemo(() => {
    switch (direction) {
      case 'ltr':
        return 'left-0 tootip-ltr';
      case 'rtl':
        return 'right-0 tooltip-rtl';
      default:
        return 'left-0 tooltip-ltr';
    }
  }, [direction]);

  return (
    <div className="relative">
      <div className={cn('group inline-block', className)}>
        {children}
        {tip ? (
          <div
            className={cn(
              'hidden group-hover:block transition absolute bottom-8 bg-white rounded-md p-3 border',
              'shadow-primary-md arrow-tooltip z-20 border-gray-100',
              directionClassname
            )}
          >
            <p className="text-gray-700 text-sm">{tip}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
}

ToolTip.defaultProps = {
  className: '',
  direction: 'ltr',
};

ToolTip.propTypes = {
  tip: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)])
    .isRequired,
  className: PropTypes.string,
  children: PropTypes.instanceOf(Object).isRequired,
  direction: PropTypes.oneOf(['rtl', 'ltr']),
};

export default ToolTip;
