import React from 'react';
import PropTypes from 'prop-types';

// import Button from 'partial/components/Button';
import { ModalCard } from 'partial/components/Modal';
import { useDatasetOptions } from 'core-modules/dataset/hooks';
import { HiCheckCircle } from 'react-icons/hi';

const ListLoader = () => {
  return (
    <div className="flex flex-col space-y-2">
      <div className="w-full h-6 rounded bg-gray-300 animate-pulse" />
      <div className="w-full h-6 rounded bg-gray-300 animate-pulse" />
      <div className="w-full h-6 rounded bg-gray-300 animate-pulse" />
      <div className="w-full h-6 rounded bg-gray-300 animate-pulse" />
      <div className="w-full h-6 rounded bg-gray-300 animate-pulse" />
    </div>
  );
};

function ValidIDs({ onClose }) {
  const [isLoading, data] = useDatasetOptions('VALID_ID');
  return (
    <ModalCard title="Valid IDs" size="sm" onClose={onClose}>
      <div className="w-full p-5">
        <p className="text-gray-500 text-base mb-4">
          Here are the recommended IDs you can submit to immediately get
          verified:
        </p>
        {isLoading ? (
          <ListLoader />
        ) : (
          <ul className="text-base text-gray-800 grid grid-cols-2 gap-2">
            {data?.map((item) => (
              <li key={item?.label} className="flex items-center space-x-2">
                <HiCheckCircle className="w-4 h-4 flex-shrink-0 text-green-500" />
                <span className="text-sm">{item?.label}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
      {/* <div className="bg-gray-50 flex justify-end mx-5">
        <Button type="button" onClick={onClose} className="ml-4 my-4">
          Close
        </Button>
      </div> */}
    </ModalCard>
  );
}

ValidIDs.propTypes = {
  onClose: PropTypes.instanceOf(Function).isRequired,
};

export default ValidIDs;
