import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.warn(error, errorInfo); // eslint-disable-line
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <p>
            Unable to load page. <br />
            <a href={get(window, 'location.pathname')}>Try again</a>
          </p>
        </div>
      );
    }
    const { children } = this.props;
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};

export default ErrorBoundary;
