import React from 'react';

const STATIC_VERSION = '2.0.0';

function Version() {
  const [version, setVersion] = React.useState(STATIC_VERSION);
  React.useEffect(() => {
    (async () => {
      if (STATIC_VERSION) return;
      try {
        const res = await fetch('/version');
        setVersion(await res.text());
      } catch (err) {
        setVersion('v0.0');
      }
    })();
  }, []);
  return <span>{version}</span>;
}

Version.propTypes = {};

export default Version;
