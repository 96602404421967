import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { toastError } from 'react-geek-toast';
import Button from 'partial/components/Button';
import { HiLocationMarker } from 'react-icons/hi';
import { useOnClickOutside, useScript } from 'helper';
import { Popover, Transition } from '@headlessui/react';
import mapStyle from './map-style';
import { useInputId } from './hooks';

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const parseCenter = (geoloc) => {
  try {
    const arr = (geoloc || '').split(',');
    return {
      lat: +(arr[0] || '14.5995').trim(),
      lng: +(arr[1] || '120.9842').trim(),
    };
  } catch (err) {
    return { lat: 14.5995, lng: 120.9842 };
  }
};
const transformCenter = (location) => {
  try {
    return `${location.lat()},${location.lng()}`;
  } catch (err) {
    return null;
  }
};

let lastZoom = 13;

const Maps = ({ name, setRef, defaultCenter, onChange, onSetFieldValue }) => {
  const mapRef = React.useRef();
  const userFirstAction = React.useRef(false);
  React.useEffect(() => {
    // eslint-disable-next-line no-undef
    const g = google;
    const map = new g.maps.Map(mapRef.current, {
      mapTypeId: 'roadmap',
      disableDefaultUI: true,
      zoom: lastZoom,
      center: parseCenter(defaultCenter),
      zoomControl: true,
      zoomControlOptions: {
        position: g.maps.ControlPosition.RIGHT_TOP,
      },
      fullscreenControl: true,
    });
    const styledMapType = new g.maps.StyledMapType(mapStyle, {
      name: 'Custom',
    });
    map.mapTypes.set('styled_map', styledMapType);
    map.setMapTypeId('styled_map');

    let isDragging = false;
    g.maps.event.addListener(map, 'zoom_changed', () => {
      lastZoom = map.getZoom();
    });
    g.maps.event.addListener(map, 'idle', () => {
      if (!isDragging) {
        // do what you want to
        if (typeof onChange === 'function') {
          onChange((state) => ({
            ...state,
            [name]: transformCenter(map.getCenter()),
          }));
        }
        if (typeof onSetFieldValue === 'function') {
          onSetFieldValue(name, transformCenter(map.getCenter()));
        }
      }
    });
    g.maps.event.addListener(map, 'dragstart', () => {
      userFirstAction.current = true;
      isDragging = true;
    });
    g.maps.event.addListener(map, 'dragend', () => {
      isDragging = false;
      g.maps.event.trigger(this, 'idle', {});
    });

    if (navigator.geolocation && !defaultCenter) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (userFirstAction.current === true) return;
        const initialLocation = new g.maps.LatLng(
          position.coords.latitude,
          position.coords.longitude
        );
        map.setCenter(initialLocation);
      });
    }

    setRef(map);
    // eslint-disable-next-line
  }, [name, onChange, onSetFieldValue]);
  return (
    <div className="bg-white rounded-lg mt-2 p-3 relative">
      <div className="aspect-h-9 aspect-w-16">
        <div ref={mapRef} className="h-full w-full" />
      </div>
      <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-100%]">
        <HiLocationMarker className="h-10 w-10 text-primary-500" />
      </div>
    </div>
  );
};

Maps.defaultProps = {
  onChange: false,
  onSetFieldValue: false,
};

Maps.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onSetFieldValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  setRef: PropTypes.func.isRequired,
  defaultCenter: PropTypes.string.isRequired,
};

function FormAddressInput({
  id: defaultId,
  name,
  geolocName,
  onChange,
  onSetFieldValue,
  value,
  error,
  geolocValue,
  label,
  required,
}) {
  const inputRef = React.useRef();
  const mapRef = React.useRef();

  const [isLoaded] = useScript(
    'google-api-script',
    `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places&region=ph`
  );
  React.useEffect(() => {
    if (!isLoaded) return;
    try {
      // eslint-disable-next-line no-undef
      const g = google;

      const autocomplete = new g.maps.places.Autocomplete(inputRef.current, {
        types: ['geocode', 'establishment'],
        componentRestrictions: { country: 'ph' },
      });
      autocomplete.addListener('place_changed', () => {
        try {
          const place = autocomplete.getPlace();
          const address = inputRef.current.value;
          if (typeof onChange === 'function') {
            onChange((state) => ({
              ...state,
              [name]: address,
              [geolocName]: transformCenter(place.geometry.location),
            }));
          }
          if (typeof onSetFieldValue === 'function') {
            onSetFieldValue(name, address);
            onSetFieldValue(
              geolocName,
              transformCenter(place.geometry.location)
            );
          }
          mapRef.current.fitBounds(place.geometry.viewport);
          mapRef.current.setCenter(place.geometry.location);
        } catch (err) {
          toastError('Unable to get place!');
        }
      });
      inputRef.current.placeholder = 'Enter Address...';
    } catch (err) {
      // DO NOTHING
    }
  }, [isLoaded, onChange, onSetFieldValue, name, geolocName]);

  const [show, setShow] = React.useState(false);
  const myRef = React.useRef();
  useOnClickOutside(myRef, setShow, 'pac-container pac-logo');
  const [id] = useInputId(defaultId);
  const handleSetRef = (map_ref) => {
    mapRef.current = map_ref;
  };
  const handleSetValue = () => {
    if (typeof onChange === 'function') {
      onChange((state) => ({
        ...state,
        [name]: inputRef.current.value,
      }));
    }
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, inputRef.current.value);
    }
  };
  const isError = !!error;
  return (
    <Popover>
      <div ref={myRef} className="relative z-[1]">
        <div
          className={cn(
            'relative border rounded-md bg-white px-3 py-2 focus-within:ring-1',
            isError
              ? 'border-red-300 focus-within:ring-red-500 focus-within:border-red-500 animate-wiggle'
              : 'border-gray-200 focus-within:ring-primary-600 focus-within:border-primary-600'
          )}
        >
          <label
            htmlFor={id}
            className="w-full text-xs font-medium text-primary-500 flex justify-between"
          >
            <span>{label}</span>
            {!required && (
              <span className="tracking-wider px-2 text-[8px] py-0.5 text-gray-500 rounded-full bg-gray-100 uppercase ml-1">
                optional
              </span>
            )}
          </label>
          <input
            ref={inputRef}
            id={id}
            type="text"
            name="name"
            onFocus={() => setShow(true)}
            onBlur={handleSetValue}
            className={cn(
              'block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-base h-[17px]',
              'focus:outline-none',
              'pr-10'
            )}
            disabled={!isLoaded}
            defaultValue={value}
          />
          {show ? (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center z-10">
              <Button xs primary onClick={() => setShow(false)}>
                Done
              </Button>
            </div>
          ) : (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <HiLocationMarker
                className={cn(
                  'h-5 w-5 text-gray-400',
                  !geolocValue ? 'opacity-50' : 'opacity-100'
                )}
                aria-hidden="true"
              />
            </div>
          )}
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 -translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-1"
          show={show && isLoaded}
        >
          <Popover.Panel
            static
            className="absolute z-10 inset-x-0 transform shadow-lg"
          >
            <Maps
              setRef={handleSetRef}
              defaultCenter={geolocValue}
              name={geolocName}
              onChange={onChange}
              onSetFieldValue={onSetFieldValue}
            />
          </Popover.Panel>
        </Transition>
      </div>
    </Popover>
  );
}

FormAddressInput.defaultProps = {
  id: '',
  required: false,
  error: null,
  onChange: false,
  onSetFieldValue: false,
};

FormAddressInput.propTypes = {
  id: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onSetFieldValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  geolocName: PropTypes.string.isRequired,
  geolocValue: PropTypes.string.isRequired,
  error: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
};

export default FormAddressInput;
