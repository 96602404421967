import React from 'react';
import PropTypes from 'prop-types';

function Splash({ label }) {
  return (
    <div className="h-full w-full flex">
      <div className="text-center m-auto w-full max-w-sm loader-wrapper">
        <div className="text-gray-600">{label}</div>
        <div className="loaderBar">
          <div className="loaderBarInner" />
        </div>
      </div>
    </div>
  );
}

Splash.defaultProps = {
  label: 'Loading...',
};

Splash.propTypes = {
  label: PropTypes.string,
};

export default Splash;
