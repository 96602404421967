import React, { useCallback } from 'react';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';

import { ModalCard, useCreateModal } from 'partial/components/Modal';
import Button from 'partial/components/Button';

export const useOpenRejectionRemarksModal = (
  options = {
    onContinue: null,
  }
) => {
  const createModal = useCreateModal();

  const openRejectionRemarlsModal = useCallback(
    (remarks) => {
      createModal({
        content: (close) => (
          <RejectionRemarksModal
            onClose={close}
            remarks={remarks}
            onContinue={options?.onContinue ?? null}
          />
        ),
      });
    },
    [createModal, options?.onContinue]
  );

  return openRejectionRemarlsModal;
};

function RejectionRemarksModal({ onClose, remarks, onContinue }) {
  return (
    <ModalCard title="Remarks" onClose={onClose}>
      <div
        className="prose prose-sm max-w-none p-4"
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(remarks || ''),
        }}
      />
      <div className="bg-gray-100 w-full relative py-4 px-6 flex justify-end rounded-b-lg space-x-4">
        <Button onClick={onClose}>Close</Button>
        {typeof onContinue === 'function' && (
          <Button
            primary
            onClick={() => {
              onClose();
              onContinue();
            }}
          >
            Continue
          </Button>
        )}
      </div>
    </ModalCard>
  );
}

RejectionRemarksModal.defaultProps = {
  onContinue: null,
};

RejectionRemarksModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  remarks: PropTypes.string.isRequired,
  onContinue: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.func,
  ]),
};

export default RejectionRemarksModal;
